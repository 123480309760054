import React, { Component } from 'react';

class Blog extends Component {

    componentDidMount() {
    }

    render() {

        return (
            <React.Fragment>
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        {/* <!-- Post preview--> */}
                        <div className="post-preview">
                            <a href="https://online.stopwatch-timer.net/">
                                <h2 className="post-title">Online Stopwatch</h2>
                                <h3 className="post-subtitle">Online stopwatch (chronometer) to mark time intervals.</h3>
                            </a>
                            <p className="post-meta">
                                Posted by <a href="#!">Stopwatch-Timer</a>
                            </p>
                        </div>
                        {/* <!-- Divider--> */}
                        <hr className="my-4" />
                        {/* <!-- Post preview--> */}
                        <div className="post-preview">
                            <a href="https://online-timer.me/">
                                <h2 className="post-title">Online Timer</h2>
                                <h3 className="post-subtitle">Programmable timer online. Easy and free Online Timer with alarm sound. Big timer with fullscreen option. Digital timer for classroom, training or for studying.</h3>
                            </a>
                            <p className="post-meta">
                                Posted by <a href="#!">Online Timer</a>
                            </p>
                        </div>
                        {/* <!-- Divider--> */}
                        <hr className="my-4" />
                        {/* <!-- Post preview--> */}
                        <div className="post-preview">
                            <a href="https://alarmclock.cloud/">
                                <h2 className="post-title">Online Alarm Clock</h2>
                                <h3 className="post-subtitle">The best online alarm clock to remind you when to wake up or in meetings, studies and more. Free alarm clock web app with full screen option.</h3>
                            </a>
                            <p className="post-meta">
                                Posted by <a href="#!">Online Alarm Clock</a>
                            </p>
                        </div>
                        {/* <!-- Divider--> */}
                        <hr className="my-4" />
                        {/* <!-- Post preview--> */}
                        <div className="post-preview">
                            <a href="https://timenowin.net/">
                                <h2 className="post-title">Online World Clock & Current Time</h2>
                                <h3 className="post-subtitle">The current time in different places in the world. Digital clock with seconds and full screen feature with large digits. </h3>
                            </a>
                            <p className="post-meta">
                                Posted by <a href="#!">Time Now In</a>
                            </p>
                        </div>
                        {/* <!-- Divider--> */}
                        <hr className="my-4" />
                        {/* <!-- Pager--> */}
                        <div className="d-flex justify-content-end mb-4"><a className="btn btn-primary text-uppercase" href="#">Older Posts →</a></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }


}

export default Blog;