import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from "react-router-dom";

import App from './main/App'

const domNode = document.getElementById('root');
const reactNode = (
      <BrowserRouter>
        <App />
      </BrowserRouter>
);

//module.hot ? createRoot(domNode).render(reactNode) : hydrateRoot(domNode, reactNode);
createRoot(domNode).render(reactNode)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
