import React, { useState, useEffect } from 'react'

import Header from '../common/template/header';
import Footer from '../common/template/footer';
import Routes from './routes';

const App = (props) => {

    return (
        <React.Fragment>
            <Header />
            <div className="container px-4 px-lg-5">
                <Routes />
            </div>
            <Footer host={props.host}/>
        </React.Fragment>
    )
}

export default App