import React from 'react'
import { Routes, Route } from 'react-router'
import { useLocation } from "react-router-dom";

import Blog from '../blog/Blog'
// import NotFound from '../notFound/NotFound'

const Router = (props) => {
    const location = useLocation();
    //console.log(location); 
    return (
        <Routes>
            <Route exact path='/' element={<Blog location={location} />} />
            <Route path='*' element={<Blog location={location} />} />
        </Routes>
    )
}

export default Router